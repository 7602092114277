<template>
	<div>
		<v-col offset-md="4" xl="6" md="5" sm="12" cols="12" class="d-flex align-center">
			<div class="div-users mr-8">
				<v-avatar class="avatar" color="white" size="40">
					<span class="headline ortho-text-green">RO</span>
				</v-avatar>
				<v-avatar class="avatar" color="white" size="40">
					<span class="headline ortho-text-green">TJ</span>
				</v-avatar>
				<v-avatar class="avatar" color="white" size="40">
					<span class="headline ortho-text-green">AP</span>
				</v-avatar>
				<v-avatar class="avatar" color="white" size="40">
					<span class="headline ortho-text-green">JD</span>
				</v-avatar>
			</div>
			<span class="white--text ml-4">{{ randomNumber }} utilisateurs ont terminé leur programme aujourd'hui </span>
		</v-col>
	</div>
</template>

<script>
export default {
	name: 'Badges',
	computed: {
		randomNumber() {
			const min = 100;
			const max = 300;
			return Math.floor(Math.random() * (max - min + 1)) + min;
		},
	},
};
</script>

<style lang="scss" scoped>
.headline {
	font-size: 15px !important;
}
.div-users {
	position: relative;
	div:not(:first-child) {
		position: absolute;
		&:nth-child(2) {
			right: -12px;
		}
		&:nth-child(3) {
			right: -24px;
		}
		&:nth-child(4) {
			right: -40px;
		}
	}
	.avatar {
		border: 1px solid #25b29b !important;
	}
}
</style>
